import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LeaderboardService } from 'src/app/core/leaderboard.service';


@Component({
  selector: 'student-leaderboard-certificate',
  templateUrl: './student-leaderboard-certificate.component.html',
  styleUrls: ['./student-leaderboard-certificate.component.scss']
})
export class StudentLeaderboardCertificateComponent implements OnInit {
  leaderboardName: string;
  endDate: string;
  displayName: string;
  myScore: number;
  myRank: number;
  groupName: string;
  groupScore: number;
  groupRank: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ls: LeaderboardService,
  ) {

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      let leaderboardId: string = params['leaderboardId'];
      let uid: string = params['uid'];
      this.ls.getLeaderboardById(leaderboardId).then(leaderboard => {
        this.ls.getMyLeaderboardGroup(leaderboard, false, uid).then(myGroup => {
          if (!myGroup) {
            return;
          }
          this.ls.getTopEntries(leaderboard, 99).then(groupScores => {
            let myGroupScore = _.find(groupScores, {groupId: myGroup.__id});
            this.ls.getUserBoosterScoresForGroup(leaderboard, myGroup).then(scores => {
              let score = _.find(scores, {uid});

              this.leaderboardName = leaderboard.name;
              this.endDate = (new Date(leaderboard.dateClosed.seconds * 1000)).toDateString();
              this.displayName = score.name;
              this.myScore = score.score;
              this.myRank = score.__rank;
              this.groupName = myGroup.name;
              this.groupScore = myGroupScore.score;
              this.groupRank = myGroupScore.__rank;
            });
          });
        });
      });
    });
  }
}
